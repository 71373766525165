import { AlertMessage, AlertStatusSuccess } from "../components/ModalPopUp";
import { ErrorHandling } from "./ErrorHandling";
import axios from "./axios";

export function updateAdmissionPhase(
  setSts,
  navigate,
  id,
  code,
  increment,
  name,
  startDate,
  endDate,
  testSchedule,
  amount,
  educationAmount,
  description,
  eduId,
  setIsLoading
) {
  axios
    .put(
      process.env.REACT_APP_BASE_URL + `/admission/${code}/phase/${id}`,
      {
        increment,
        name,
        startDate,
        endDate,
        testSchedule,
        amount,
      },
      { headers: { authorization: localStorage.getItem("TOKEN") } }
    )
    .then(() => {
      const sequence = 1;
      axios
        .put(
          process.env.REACT_APP_BASE_URL + `/admission/${code}/detail/${eduId}`,
          {
            description,
            amount: educationAmount,
            sequence,
          },
          { headers: { authorization: localStorage.getItem("TOKEN") } }
        )
        .then(() => {
          setIsLoading(false);
          setSts({ type: "success" });
          AlertStatusSuccess(
            navigate,
            "Berhasil",
            "Kembali Ke Detail Pendaftaran",
            "success",
            "Ubah Gelombang Berhasil"
          );
        })
        .catch((error) => {
          setIsLoading(false);
          setSts({ type: "error", error });
          ErrorHandling(error);
        });
    })
    .catch((error) => {
      setIsLoading(false);
      setSts({ type: "error", error });
      ErrorHandling(error);
    });
}

export function postAdmissionPhase(
  setSts,
  navigate,
  code,
  increment,
  name,
  startDate,
  endDate,
  testSchedule,
  amount,
  setIsLoading
) {
  axios
    .post(
      process.env.REACT_APP_BASE_URL + `/admission/${code}/phase`,
      {
        increment,
        name,
        startDate,
        endDate,
        testSchedule,
        amount,
      },
      { headers: { authorization: localStorage.getItem("TOKEN") } }
    )
    .then(() => {
      setIsLoading(false);
      setSts({ type: "success" });
      AlertStatusSuccess(
        navigate,
        "Berhasil",
        "Tutup",
        "success",
        "Tambah Gelombang Berhasil"
      );
    })
    .catch((error) => {
      setIsLoading(false);
      setSts({ type: "error", error });
      ErrorHandling(error);
    });
}
