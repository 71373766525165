import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { useRef, useState } from "react";
import { AiOutlineSave } from "react-icons/ai";
import { BsChevronLeft } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { Header } from "../../components";
import { AlertMessage, AlertStatusSuccess } from "../../components/ModalPopUp";
import { FileUpload } from "../../components/FileUpload";
const BerkasPembayaran = () => {
  const token = localStorage.getItem("TOKEN");
  const regNumber = localStorage.getItem("REG_NUMBER");
  const SUBMIT_URL = `/admission/registration/${regNumber}/invoice`;
  const [isLoading, setIsLoading] = useState(false);
  const uploaderRef = useRef(null);
  const [filesData, setFilesData] = useState(null);
  const navigate = useNavigate();
  const path = "/pmb/tahapan-pmb";

  const navigateSteps = () => {
    window.location.href = "/pmb/tahapan-pmb";
  };

  // const handleImagesChange = (event) => {
  //   setFilesData(event.target.files[0]);
  // };

  const handleInputChange = (e) => {
    const files = e.target.files;
    setFilesData(files);
    // setFilesData(files);
  };

  // // Define your asyncSettings for the UploaderComponent (modify this as needed)
  // const asyncSettings = {
  //   saveUrl:
  //     "https://services.syncfusion.com/react/production/api/FileUploader/Save",
  //   removeUrl:
  //     "https://services.syncfusion.com/react/production/api/FileUploader/Save",
  // };

  // // Define your minFileSize and maxFileSize (modify these as needed)
  // const minFileSize = 0;
  // const maxFileSize = 5000000;

  // // Function to handle removing a file
  // const onRemoveFile = (args) => {
  //   // setFileInvoice(null);
  // };

  // // Function to handle uploading a file
  // const onFileUpload = (args) => {};

  // // Function to handle upload success
  // const onSuccess = (args) => {
  //   setFilesData(args);
  // };

  // Function to handle file upload to the API using Axios
  const handleFileUpload = () => {
    if (!filesData) {
      AlertMessage(
        "File Kosong",
        "Coba Lagi",
        "warning",
        "Mohon Upload File Terlebih Dahulu"
      );
    }

    const invoice = filesData;
    axios
      .post(
        SUBMIT_URL,
        {
          invoice,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      )
      .then(() => {
        AlertStatusSuccess(
          navigateSteps,
          "Berhasil",
          "Kembali Ke Halaman Tahapan PMB",
          "success",
          "Unggah Bukti Pembayaran Pendaftaran Berhasil"
        );
      })
      .catch(() => {
        AlertMessage(
          "Gagal",
          "Coba Lagi",
          "Unggah Bukti Pembayaran Gagal",
          "error"
        );
      });
  };

  return (
    <article>
      <Header
        home="PMB"
        prev="Tahapan"
        navPrev={path}
        at="Bukti Pembayaran"
        title="Form Bukti Pembayaran"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          marginBottom: "70px",
        }}
      >
        {/* COL 1 */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "20px",
            width: "100%",
            gap: "5px",
          }}
        >
          <p className="">Upload Bukti Pembayaran</p>
          <FileUpload
            setFilesData={setFilesData}
            filesData={filesData}
            handleInputChange={handleInputChange}
            fileInputId="fileInput1"
          />
        </div>
      </div>

      <button
        type="button"
        className="w-auto btn-merah"
        onClick={handleFileUpload}
      >
        {isLoading ? (
          <CgSpinner className="mr-2 text-xl animate-spin" />
        ) : (
          <AiOutlineSave className="mr-2 text-2xl" />
        )}
        Unggah
      </button>
      <section className="flex mt-1 justify-start">
        <Link
          to={path}
          className="bg-transparent shadow-none w-auto btn-navigate hover:bg-transparent text-merah hover:text-gelap"
        >
          <BsChevronLeft className="text-xl mr-7 mt-0.5" /> Halaman Tahapan PMB
        </Link>
      </section>
    </article>
  );
};

export default BerkasPembayaran;
