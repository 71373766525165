import { useRef, useState } from "react";
import { AiOutlineSave } from "react-icons/ai";
import { BsChevronLeft } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import { Header } from "../../components";
import {
  DropdownDatePickers,
  DropdownPendaftaran,
} from "../../components/Dropdown";
import { AlertMessage, AlertStatusSuccess } from "../../components/ModalPopUp";
import TextInput from "../../components/TextInput";
import { FileUpload } from "../../components/FileUpload";

const BerkasPembayaranBiayaPendidikan = () => {
  const token = localStorage.getItem("TOKEN");
  const regNumber = localStorage.getItem("REG_NUMBER");
  const SUBMIT_URL = `/admission/registration/${regNumber}/payment`;
  const [isLoading, setIsLoading] = useState(false);
  const path = "/pmb/tahapan-pmb";
  const [fileInvoice, setFileInvoice] = useState(null);
  const uploaderRef = useRef(null);
  const [jumlah, setJumlah] = useState(null);
  const [tanggal, setTanggal] = useState(null);
  const [metode, setMetode] = useState(null);

  const navigateTahapan = () => {
    window.location.href = path;
  };

  const handleFileUpload = () => {
    setIsLoading(true);
    // const paymentRecipt = filesData.file.rawFile;
    const paymentRecipt = fileInvoice;
    const amount = parseInt(jumlah.replace(/\./g, ""), 10);
    const paymentDatetime = new Date(tanggal).toISOString();
    const paymentMethod = metode;

    axios
      .post(
        SUBMIT_URL,
        {
          amount,
          paymentDatetime,
          paymentMethod,
          paymentRecipt,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        AlertStatusSuccess(
          navigateTahapan,
          "Berhasil",
          "Kembali Ke Halaman Tahapan PMB",
          "success",
          "Unggah Bukti Pembayaran Biaya Pendidikan Berhasil"
        );
      })
      .catch((error) => {
        setIsLoading(false);
        AlertMessage("Gagal", "Unggah Gagal", "Coba Lagi", "error");
      });
  };

  const handleInputChange = (event) => {
    let inputVal = event.target.value;
    inputVal = inputVal.replace(/\D/g, "");
    inputVal = inputVal.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    setJumlah(inputVal);
  };

  const options = [
    {
      value: "transfer",
      label: "Transfer",
    },
    {
      value: "cash",
      label: "Cash",
    },
  ];

  return (
    <article>
      <Header
        home="PMB"
        // prev="Bank"
        // navPrev={path}
        at="Bukti Pembayaran Biaya Pendidikan"
        title="Form Bukti Pembayaran Biaya Pendidikan"
      />
      <div>
        <section>
          <DropdownPendaftaran
            label="Metode Pembayaran"
            required={true}
            isClearable={true}
            isSearchable={false}
            defaultValue={metode}
            options={options}
            onChange={(e) => setMetode(e.value)}
          />

          <TextInput
            label="Jumlah Transfer"
            type="text"
            id="amount"
            onChange={handleInputChange}
            value={jumlah}
            // placeholder={admissionParentsData.birthPlace}
            disable={false}
            required={true}
          />

          <DropdownDatePickers
            label="Tanggal Transaksi"
            id="paymentDatetime"
            value={tanggal}
            change={(e) => setTanggal(e.element.value)}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              marginTop: "20px",
              width: "auto",
            }}
          >
            <FileUpload
              setFilesData={setFileInvoice}
              filesData={fileInvoice}
              fileInputId={"fileInput1"}
            />
          </div>
        </section>
      </div>

      <button className="btn-merah" onClick={handleFileUpload}>
        {isLoading ? (
          <CgSpinner className="mr-2 text-xl animate-spin" />
        ) : (
          <AiOutlineSave className="mr-2 text-2xl" />
        )}
        Kirim
      </button>
      <section className="flex mt-1 gap-5 justify-left">
        <Link
          to={"/pmb/tahapan-pmb"}
          className="bg-transparent shadow-none btn-navigate hover:bg-transparent text-merah hover:text-gelap"
        >
          <BsChevronLeft className="text-xl mr-7 mt-0.5" /> Halaman Tahapan PMB
        </Link>
      </section>
    </article>
  );
};

export default BerkasPembayaranBiayaPendidikan;
