import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswers, postQuestion, updateQuestion } from "../../../api/Sarat";
import { Header } from "../../../components";
import { DropdownRadioInputBiological } from "../../../components/Dropdown";
import { AlertMessage } from "../../../components/ModalPopUp";
import TextInput, { TextArea } from "../../../components/TextInput";
import { useStateContext } from "../../../contexts/ContextProvider";
import { CircularProgress } from "@mui/material";

export default function TambahAnswers() {
  const location = useLocation();
  const path = "/admin/detail-soal";
  const [fields, setFields] = useState([
    {
      id: 0,
      answer_choice: "",
      is_correct: "",
      sequence: location.state.sequence,
    },
  ]);
  const [sts, setSts] = useState("");
  const { isLoading, setIsLoading } = useStateContext();

  const navigate = useNavigate();
  const navigateListAnswers = () => {
    navigate(path, {
      state: {
        question_id: location.state.question_id,
      },
    });
  };

  const postData = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const questions = [
      {
        id: location.state.question_id,
        question: location.state.question,
        question_type: "PG",
        flag: `${localStorage.getItem("FLAG")}`,
        sequence: location.state.detail_question_sequence,
        is_publish: location.state.is_publish,
        question_lists: fields,
      },
    ];

    if (fields.answer_choice === "") {
      AlertMessage("Gagal", "Input Data Tidak Lengkap", "Coba Lagi", "warning");
      setIsLoading(false);
    } else {
      updateQuestion(
        location.state.question_id,
        setSts,
        navigateDetailQuestion,
        location.state.session_detail_id,
        questions,
        setIsLoading
      );
    }
  };

  const navigateDetailQuestion = () => {
    navigate(path, {
      state: {
        id: location.state.id,
        sequence: location.state.detail_question_sequence,
        question_id: location.state.question_id,
        session_detail_id: location.state.session_detail_id,
        question: location.state.question,
        is_publish: location.state.is_publish,
      },
    });
  };

  const handleFieldChange = (index, fieldName, value) => {
    const newFields = [...fields];
    newFields[index][fieldName] = value;
    setFields(newFields);
  };

  const addQField = () => {
    setFields([
      ...fields,
      {
        id: 0,
        answer_choice: "",
        is_correct: "",
        sequence: location.state.sequence,
      },
    ]);
  };

  const removeQField = () => {
    const newFields = [...fields];
    newFields.pop();
    setFields(newFields);
  };

  return (
    <>
      <Header
        home="Admin SARAT"
        prev="Jawaban"
        navPrev={path}
        at="Tambah Pilihan Jawaban"
        title="Tambah Pilihan Jawaban"
      />

      <div style={{ padding: "40px 104px 0" }}>
        <article>
          <br />
          <p className="font-bold text-merah mr-8">
            Form Tambah Pilihan Jawaban
          </p>
          {fields.map((field, index) => (
            <div key={index}>
              <br />
              <TextInput
                label="Jawaban"
                type="text"
                value={field.answer_choice}
                required={true}
                onChange={(e) => {
                  handleFieldChange(index, "answer_choice", e.target.value);
                }}
              />
              <DropdownRadioInputBiological
                required={true}
                label="Benar"
                value1={1}
                value2={0}
                label2="Ya"
                label3="Tidak"
                onChange={(e) => {
                  handleFieldChange(
                    index,
                    "is_correct",
                    parseInt(e.target.value)
                  );
                }}
                checked={field.is_correct}
              />
              <br />
            </div>
          ))}
          <div className="mr-10 flex justify-end py-5 gap-2">
            <button
              className="btn-mrh w-auto flex items-center text-sm gap-1"
              title="Kurangi Kolom"
              onClick={removeQField}
            >
              <i className="mt-1 fa fa-minus" /> Kurangi
            </button>
            <button
              className="btn-hijau w-auto flex items-center text-sm gap-1"
              title="Tambah Kolom "
              onClick={addQField}
            >
              <i className="mt-1 fa fa-plus" /> Tambah
            </button>
          </div>
          <br />
          <div className="btn-form mr-7 flex justify-center items-center">
            {isLoading && <CircularProgress size={24} className="mr-8" />}
            <button
              type="button"
              className="w-20 btn-merah flex justify-center mb-5"
              onClick={postData}
            >
              Simpan
            </button>
            <button
              type="button"
              className="w-20 btn-putih flex justify-center mb-5"
              onClick={navigateDetailQuestion}
            >
              Batal
            </button>
          </div>
        </article>
      </div>
    </>
  );
}
